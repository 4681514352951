import WhiteNavbar from 'components/Navbars/WhiteNavbar'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Container } from 'reactstrap'
import ReactGA from "react-ga4"

const ContactComplete = () => {
  React.useEffect(() => {

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Contact Complete Page | Bordo Stone"
    })
  }, [])
  return (
    <>
    <Helmet>
      <title>Contact Bordo Stone | Premium Stone Solutions in North Caldwell, NJ</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Contact Bordo Stone for expert stone assistance in North Caldwell, NJ. Transform your spaces with quartz, granite, marble, and quartzite."
        data-rh="true"
      />
      <link rel="canonical" href='/contat-us' />
      <meta property="og:title" content="Quality Stone Surfaces in North Caldwell, NJ" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone. Elevate your spaces with premium stones in North Caldwell, NJ." />
        <meta property="og:image" content="https://www.bordostone.com/static/media/logo.9f20e4f187ea1fd80163.png" />
        <meta property="og:url" content="https://www.bordostone.com/contact-complete" />
    </Helmet>
    <WhiteNavbar navbarSelection={"contact-us"}/>
    <div className='wrapper features-1' style={{marginTop:"10rem"}}>
        <Container> 
         
        <h2 className='header-3'>Thank you for choosing us</h2>
        <a className="text-info" href="/">&larr;&nbsp;Back to Home Page</a>
        </Container>
        
    </div>
    </>
    
  )
}

export default ContactComplete