import Filter from 'components/Filter/Filter'
import Footer from 'components/Footers/Footer'
import WhiteNavbar from 'components/Navbars/WhiteNavbar'
import React from 'react'
import { Container } from 'reactstrap'
import ReactGA from "react-ga4"
import { Helmet } from 'react-helmet-async'

const ExploreStones = () => {
  React.useEffect(() => {
   
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Expore Stones Page | Bordo Stone"
    })
  }, [])
  return (
    <>
    <Helmet>
      <title>Explore Our Stone Collection | Quartz, Granite, Marble, Quartzite</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Explore Bordo Stone's stone collection - quartz, granite, marble, quartzite. Elevate your spaces with elegance and durability."
        data-rh="true"
      />
      <link rel="canonical" href='/explore-stones' />
      <meta property="og:title" content="Bordo Stone | Quality Stone Surfaces in North Caldwell, NJ" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone. Elevate your spaces with premium stones in North Caldwell, NJ." />
        <meta property="og:image" content="https://www.bordostone.com/static/media/logo.9f20e4f187ea1fd80163.png" />
        <meta property="og:url" content="https://www.bordostone.com/home" />
    </Helmet>
    <WhiteNavbar navbarSelection={"explore-stones"}/>
    <Filter/>
    <hr></hr>
    <footer className="footer" style={{paddingTop:"0"}}> 
      <Container>
      <div className="copyright" id="copyright">© {new Date().getFullYear()}, Designed by{" "}<a className="text-danger" href="https://topstonesolutions.com/">Topstone Solutions</a></div>
    </Container>
    </footer>
    </>
  )
}

export default ExploreStones