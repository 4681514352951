
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import Home from "./pages/Home";
import ExploreStones from "pages/ExploreStones";
import AboutUs from "pages/AboutUs";
import Gallery from "pages/Gallery";
import ContactUs from "pages/ContactUs";
import { Provider } from "react-redux";
import store from "redux/store";
// pages

import { HelmetProvider } from "react-helmet-async";
import ContactComplete from "components/ContactComplete/ContactComplete";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/explore-stones" element={<ExploreStones/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/contact-complete" element={<ContactComplete/>}/>
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
 
);
