import {configureStore, combineReducers} from "@reduxjs/toolkit"

import filterReducer from "./filterSlice"


// burası reucerlarımızı ekleyeceğimiz yer
const rootReducer = combineReducers({
    filter: filterReducer,
});

// burade reducerlar store a kaydediliyor.
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store