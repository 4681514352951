//// tüm filtreleme ve sıralama işlemlerinin yürütüldüğü redux: arama filtresi, kategori filtresi, marka filtresi, fiyat filtresi
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  products: [],
  typeFilteredProducts: [],
  colorFilteredProducts: [],
  countryFilteredProducts: [],
  finishFilteredProducts:[],
  filteredProducts:[]
}

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    STORE_PRODUCTS(state,action) {
        state.products = action.payload.products
        state.filteredProducts = action.payload.products
        
    },
    FILTER_BY_TYPE(state,action){
        const {data,products} = action.payload
        // console.log(data)
        let temp = [];
      for (let i = 0; i < data.length; i++)
      {
        temp = [ ...temp, ...products.filter((product) => product.type === data[ i ]?.label) ]
      }
    //   console.log(temp)
      state.typeFilteredProducts = temp
    },
    FILTER_BY_COLOR(state,action){
      
        const {data,products} = action.payload
        console.log(data,products)
        let temp = [];
      for (let i = 0; i < data.length; i++)
      {
        temp = [ ...temp, ...products.filter((product)=> product.color?.includes(data[i]?.label)) ]
      }
      console.log(temp)
      state.colorFilteredProducts = temp
    },
    FILTER_BY_COUNTRY(state,action){
        const {data,products} = action.payload
        // console.log(data)
        let temp = [];
      for (let i = 0; i < data.length; i++)
      {
        temp = [ ...temp, ...products.filter((product) => product.country === data[ i ]?.label) ]
      }
    //   console.log(temp)
      state.countryFilteredProducts = temp
    },
    FILTER_BY_FINISH(state,action){
        const {data,products} = action.payload
        let temp = [];
      for (let i = 0; i < data.length; i++)
      {
        temp = [ ...temp, ...products.filter((product)=> product.finish?.includes(data[i]?.label)) ]
      }
    //   console.log(temp)
      state.finishFilteredProducts = temp
    },
    
  }
});

export const {STORE_PRODUCTS,FILTER_BY_TYPE,FILTER_BY_COLOR,FILTER_BY_COUNTRY,FILTER_BY_FINISH} = filterSlice.actions

export const selectFilteredProducts = (state) => (state.filter.filteredProducts)
export const selectTypeFilteredProducts = (state) => (state.filter.typeFilteredProducts)
export const selectColorFilteredProducts = (state) => (state.filter.colorFilteredProducts)
export const selectCountryFilteredProducts = (state) => (state.filter.countryFilteredProducts)
export const selectFinishFilteredProducts = (state) => (state.filter.finishFilteredProducts)
export const selectProducts = (state) => state.filter.products

export default filterSlice.reducer