import AboutUsHeader from 'components/AboutUs/AboutUsHeader'
import WhiteNavbar from 'components/Navbars/WhiteNavbar'
import React, { useEffect } from 'react'
import { Badge, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async';
import ReactGA from "react-ga4"

const AboutUs = () => {
  document.body.classList.add("sidebar-collapse");
  useEffect(() => {
    AOS.init();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "About Us Page | Bordo Stone"
    })
  }, [])
  return (
    <>
    <Helmet>
      <title>Bordo Stone | Expert Stone Craftsmanship, North Caldwell</title>
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Learn about Bordo Stone's commitment to quality quartz, granite, marble, and quartzite surfaces in North Caldwell, NJ."
        data-rh="true"
      />
      <link rel="canonical" href='/about-us' />
      <meta property="og:title" content="Bordo Stone | Quality Stone Surfaces in North Caldwell, NJ" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone. Elevate your spaces with premium stones in North Caldwell, NJ." />
        <meta property="og:image" content="https://www.bordostone.com/static/media/logo.9f20e4f187ea1fd80163.png" />
        <meta property="og:url" content="https://www.bordostone.com/about-us" />
    </Helmet>
    <WhiteNavbar navbarSelection={"about-us"}/>
    <AboutUsHeader/>
    <div className="projects-5">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" data-aos="fade-up" data-aos-duration="1000" md="8">
                  <h2 className="title">We have many advantages</h2>
                  <div className="section-space"></div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/about-us/1.jpg") + ")"
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Bordostone knows your needs</CardTitle>
                      <p className="card-description">
                      High satisfaction guarantee
                      </p>
                      <Badge className="badge-neutral">Bordo Stone Tech</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5" data-aos="zoom-in" data-aos-duration="2000">
                  <div className="info info-horizontal">
                  <h3 style={{display:"none"}}>Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone.</h3>
                    <div className="icon icon-danger">
                      <i className="now-ui-icons ui-2_chat-round"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                      Endless High-Quality Choices
                      </h4>
                      <p className="description">
                      Discover a wide selection of top-quality marble, granite, dekton, quartz, and quartizite countertops to suit any style.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons business_chart-pie-36"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title"> Easy Customization Options</h4>
                      <p className="description">
                      Personalize your countertops effortlessly with our expert assistance in material, color, and edge design.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons design-2_ruler-pencil"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Timeless Durability</h4>
                      <p className="description">
                      Invest in countertops that stand the test of time, resisting scratches and maintaining their beauty.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col className="ml-auto mt-5 order-2 order-md-1" data-aos="zoom-in" data-aos-duration="2000" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons users_single-02"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Expert Craftsmanship</h4>
                      <p className="description">
                      Our skilled artisans create flawless countertops with precision and care.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons business_chart-bar-32"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Competitive Pricing</h4>
                      <p className="description">
                      Enjoy premium materials and services at affordable rates.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons arrows-1_refresh-69"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Unbeatable Customer Service</h4>
                      <p className="description">
                      Experience dedicated customer support from selection to installation.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto order-1 order-md-2" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/about-us/2.jpg") + ")"
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Discover Bordostone's products </CardTitle>
                      <p className="card-description text-white">
                      The only address you will not regret
                      </p>
                      <Badge className="badge-neutral">Bordo Stone Tech</Badge>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
    <hr></hr>
    <footer className="footer" style={{paddingTop:"0"}}> 
      <Container>
      <div className="copyright" id="copyright">© {new Date().getFullYear()}, Designed by{" "}<a className="text-danger" href="https://topstonesolutions.com/">Topstone Solutions</a></div>
    </Container>
    </footer></>
  )
}

export default AboutUs