/*eslint-disable*/
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Footer() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <footer className="footer" data-aos="fade-up" data-aos-duration="1000">
        <Container>
          <div className="content">
            <Row>
              <Col md="2" className="text-md-left text-sm-center">
                <h5>Menu</h5>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/"
                
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/explore-stones"
                     
                    >
                      Explore Stones
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/about-us"
                      
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/gallery"
                      
                    >
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/contact-us"
                      
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="2" className="text-md-left text-sm-center">
                <h5>Our Products</h5>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="#quartz"
                      
                    >
                      Quartz
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="#dekton"
                     
                    >
                      Dekton
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="#granite"
                     
                    >
                      Granite
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="#marble"
                      
                    >
                      Marble
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="#quartizite"
                      
                    >
                      Quartizite
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h5>Address</h5>
                <div className="social-feed">
                  <div className="feed-line">
                    
                    <p>
                    1 Fairfield Rd, North Caldwell, NJ, 07006
                    </p>
                  </div>
                  <div className="feed-line">
                    
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3016.531833766775!2d-74.2563547463899!3d40.8821473000735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c301af8a1bfabd%3A0x365bb5d9a53e898f!2s1%20Fairfield%20Rd%2C%20Caldwell%2C%20NJ%2007006%2C%20Amerika%20Birle%C5%9Fik%20Devletleri!5e0!3m2!1str!2str!4v1689485474032!5m2!1str!2str" width="auto" height="auto" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h5>Follow Us</h5>
                <ul className="social-buttons">
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="instagram"
                      target="_blank"
                      href="https://www.instagram.com/bordostone_/"
                      
                    >
                      <i className="fab fa-instagram"></i>
                      <span style={{display:"none"}}>Instgram Bordo Stone</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="facebook"
                      target="_blank"
                      href="https://www.facebook.com/people/Ilkem-Granite-North/pfbid02JiXpo5tmSFjmJKtYvvyw74mbfaBubdkZaGKV8nr9BTkxJw7Rx7164mDmCFekp6Nfl/?mibextid=ZbWKwL"
                    >
                      <i className="fab fa-facebook-square"></i>
                      <span style={{display:"none"}}>Facebook Bordo Stone</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="linkedin"
                      href="https://www.linkedin.com/in/bordo-stone-089116284/"
                      rel="Bordo Stone LinkedIn Account"
                      text="Bordo Stone LinkedIn Account"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                      <span style={{display:"none"}}>LinkedIn Bordo Stone</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="pinterest"
                      href="https://tr.pinterest.com/BordoStone/"
                      target="_blank"
                      
                    >
                      <i className="fab fa-pinterest-square"></i>
                      <span style={{display:"none"}}>Pinterest Bordo Stone</span>
                    </Button>
                  </li>
                </ul>
                <h5>
                  <small>E-mail</small>
                </h5>
                <h5>
                  info@bordostone.com
                </h5>
                <h5>
                  <small>Telephone</small>
                </h5>
                <h5>
                  +1 (973) 891-5566
                </h5>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Powered by{" "}<a className="text-danger" href="https://topstonesolutions.com/">Topstone Solutions</a>
            
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
