/*eslint-disable*/
import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router-dom";
import styles from "./Filter.module.scss";
import { Dna, ThreeDots } from "react-loader-spinner";

// plugin that creates slider
import Slider from "nouislider";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Collapse,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

//import data from "../../data.json";
//import data2 from "../../dataNew.json";
// core components
import Select from "react-select";
import Pagination from "components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { selectProducts } from "redux/filterSlice";
import { useEffect } from "react";
import { STORE_PRODUCTS } from "redux/filterSlice";
import { selectFilteredProducts } from "redux/filterSlice";
import { FILTER_BY_TYPE } from "redux/filterSlice";
import { selectTypeFilteredProducts } from "redux/filterSlice";
import { selectColorFilteredProducts } from "redux/filterSlice";
import { selectCountryFilteredProducts } from "redux/filterSlice";
import { selectFinishFilteredProducts } from "redux/filterSlice";
import { FILTER_BY_COLOR } from "redux/filterSlice";
import { FILTER_BY_COUNTRY } from "redux/filterSlice";
import { FILTER_BY_FINISH } from "redux/filterSlice";
import axios from "axios";

const Filter = () => {
  const [chooseType, setChooseType] = React.useState([]);
  const [chooseColor, setChooseColor] = React.useState([]);
  const [chooseCountry, setChooseCountry] = React.useState([]);
  const [chooseFinish, setChooseFinish] = React.useState([]);
  const [currentProducts, setCurrentProducts] = React.useState([]);
  const [modalStates, setModalStates] = useState(
    currentProducts.map(() => false)
  );
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const products = useSelector(selectProducts);
  // console.log(products)
  const filteredProduct = useSelector(selectFilteredProducts);
  const typeFilteredProduct = useSelector(selectTypeFilteredProducts);
  const colorFilteredProduct = useSelector(selectColorFilteredProducts);
  const countryFilteredProduct = useSelector(selectCountryFilteredProducts);
  const finishFilteredProduct = useSelector(selectFinishFilteredProducts);

  const allBrands = [
    ...new Set(
      products.map((item) => item.type).filter((value) => value !== null)
    ),
  ];
  //console.log(allBrands)
  const allColors = [
    ...new Set(
      products
        .map((item) => item.color)
        .flatMap((innerArray) => innerArray)
        .filter((value) => value !== undefined)
    ),
  ];
  // console.log(allColors)
  const allCountries = [
    ...new Set(
      products
        .map((item) => item.country)
        .filter((value) => value !== null)
    ),
  ];
  // console.log(allCountries)
  const allFinishes = [
    ...new Set(
      products
        .map((item) => {
          // console.log(item);
          return item.finish;
        })
        .flatMap((innerArray) => innerArray)
        .filter((value) => value !== undefined)
    ),
  ];
  // console.log(allFinishes)
  function convertToOption(allBrands) {
    return allBrands.map((item, index) => {
      return { value: index + 1, label: item };
    });
  }

  const optionsBrand = convertToOption(allBrands);
  // console.log(optionsBrand)
  const optionsColors = convertToOption(allColors);
  // console.log(optionsBrand)
  const optionsCountries = convertToOption(allCountries);
  // console.log(optionsBrand)
  const optionsFinishes = convertToOption(allFinishes);
  // console.log(optionsBrand)

  // şu anki sayfayı tutan state
  const [currentPage, setCurrentPage] = useState(1);
  //sayfa başına kaç ürün olacağını gösterir
  const productsPerPage = 9;

  //sayfadaki son elemanın endexi (formülizasyon alttaki slice için oluşturuldu)
  const indexOfLastProduct = currentPage * productsPerPage;
  //sayfadaki ilk elemanın endexi (formülizasyon alttaki slice için oluşturuldu)
  const indexOfFirstProduct = (currentPage - 1) * productsPerPage;
  //slice ilk elemanı alır,ikincisini almaz. Bu sebeple sayfada 9 eleman istiyorsak (0-9)(9-18)(18-27) şeklinde ilerlemeli

  useEffect(() => {
    // Fetch data2 JSON from the Django API endpoint
    axios
      .get("https://topstonesolutions.pythonanywhere.com/api/stones/")
      .then((response) => {
        const data2 = response.data;
        console.log(data2);
        dispatch(STORE_PRODUCTS({ products: data2 }));
        const imagesMap = {};
        // Create an array to store the images

        // Loop through the data and add image arrays to the fetchedImages array
        for (const product of data2) {
          const productImages = [];
          for (let i = 1; i <= 4; i++) {
            const imageUrl = product[`image${i}`];
            if (imageUrl) {
              productImages.push({
                original: imageUrl,
                thumbnail: imageUrl,
              });
            }
          }
            if(productImages.length == 0){
              productImages.push({
                original: require(`../../assets/ilkempicture/woocommerce-placeholder-300x300.png`),
                thumbnail: require(`../../assets/ilkempicture/woocommerce-placeholder-300x300.png`),
              });
            }
            imagesMap[product.id] = productImages;
        }

        setImages(imagesMap);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors if necessary
      });
  }, []);

  React.useEffect(() => {
    if (
      typeFilteredProduct.length === 0 &&
      colorFilteredProduct.length === 0 &&
      countryFilteredProduct.length === 0 &&
      finishFilteredProduct.length === 0
    )
      setCurrentProducts(
        products.slice(indexOfFirstProduct, indexOfLastProduct)
      );
    else
      setCurrentProducts(
        Array.from(
          new Set(
            typeFilteredProduct.concat(
              colorFilteredProduct,
              countryFilteredProduct,
              finishFilteredProduct
            )
          )
        ).slice(indexOfFirstProduct, indexOfLastProduct)
      );

  }, [
    indexOfFirstProduct,
    indexOfLastProduct,
    filteredProduct,
    colorFilteredProduct,
    typeFilteredProduct,
    countryFilteredProduct,
    finishFilteredProduct,
  ]);

  useEffect(()=>{
    setCurrentPage(1)
  },[colorFilteredProduct,
    typeFilteredProduct,
    countryFilteredProduct,
    finishFilteredProduct,])

  React.useEffect(() => {
    document.body.classList.add("ecommerce-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("ecommerce-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const filterByType = (data) => {
    dispatch(FILTER_BY_TYPE({ data, products }));
    setChooseType(data);
  };
  const filterByColor = (data) => {
    dispatch(FILTER_BY_COLOR({ data, products }));
    setChooseColor(data);
  };
  const filterByCountry = (data) => {
    dispatch(FILTER_BY_COUNTRY({ data, products }));
    setChooseCountry(data);
  };
  const filterByFinish = (data) => {
    dispatch(FILTER_BY_FINISH({ data, products }));
    setChooseFinish(data);
  };

  const toggleModal = (productId) => {
    const newModalStates = [...modalStates];
    newModalStates[productId] = !newModalStates[productId];
    setModalStates(newModalStates);
  };

  const handleRedirect = (product) => {
    navigate("/contact-us", { state: { data: product } });
  };

  return (
    <>
      {products.length != 0 ? (
        <div className="wrapper">
          {console.log(products.length)}
          <div className="main">
            <div className="section">
              <Container>
                <br></br>
                <Row>
                  <Col md="3" style={{ marginTop: "5em" }}>
                    <h1 style={{display:"none"}}>Filter Type, Filter Color, Filter Country, Filter Finish</h1>
                    <h2 style={{display:"none"}}>Filter Type, Filter Color, Filter Country, Filter Finish</h2>
                    <h3 style={{display:"none"}}>Filter Type, Filter Color, Filter Country, Filter Finish</h3>
                    <h4 style={{display:"none"}}>Filter Type, Filter Color, Filter Country, Filter Finish</h4>
                    <h5 style={{display:"none"}}>Filter Type, Filter Color, Filter Country, Filter Finish</h5>
                    <h6 style={{ paddingLeft: "1em",visibility:"inherit" }}>TYPE</h6>
                    <Select
                      className="react-select react-select-info mt-2"
                      onChange={(value) => filterByType(value)}
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      placeholder="Choose Type"
                      value={chooseType}
                      isMulti
                      name=""
                      options={[
                        {
                          value: "",
                          label: " Multiple Options",
                          isDisabled: true,
                        },
                        ...optionsBrand,
                       
                      ]}
                    ></Select>
                    <h6 style={{ paddingLeft: "1em" }}>COLOR</h6>
                    <Select
                      className="react-select react-select-info mt-2"
                      onChange={(value) => filterByColor(value)}
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      placeholder="Choose Color"
                      value={chooseColor}
                      isMulti
                      name=""
                      options={[
                        {
                          value: "",
                          label: " Multiple Options",
                          isDisabled: true,
                        },
                        ...optionsColors,
                        // {allBrands.map((item,index)=> {
                        //   return {value:index, label:item}
                        // })}
                        // { value: "2", label: "Quartz " },
                        // { value: "3", label: "Dekton" },
                        // { value: "4", label: "Granite" },
                        // { value: "5", label: "Quartzite" },
                        // { value: "6", label: "Marble " },
                      ]}
                    ></Select>
                    <h6 style={{ paddingLeft: "1em" }}>COUNTRY</h6>
                    <Select
                      className="react-select react-select-info mt-2"
                      onChange={(value) => filterByCountry(value)}
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      placeholder="Choose Country"
                      value={chooseCountry}
                      isMulti
                      name=""
                      options={[
                        {
                          value: "",
                          label: " Multiple Options",
                          isDisabled: true,
                        },
                        ...optionsCountries,
                      ]}
                    ></Select>
                    <h6 style={{ paddingLeft: "1em" }}>FINISH</h6>
                    <Select
                      className="react-select react-select-info mt-2"
                      onChange={(value) => filterByFinish(value)}
                      classNamePrefix="react-select"
                      closeMenuOnSelect={false}
                      placeholder="Choose Finish"
                      value={chooseFinish}
                      isMulti
                      name=""
                      options={[
                        {
                          value: "",
                          label: " Multiple Options",
                          isDisabled: true,
                        },
                        ...optionsFinishes,
                      ]}
                    ></Select>
                  </Col>
                  <Col md="9">
                    <Row>
                      {currentProducts.map((product) => {
                        return (
                          <Col lg="4" md="6" key={product.id}>
                            <Card className="card-product card-plain">
                              <div className="card-image">
                                <a
                                  href="#pablo"
                                  onClick={() => toggleModal(product.id)}
                                >
                                  <img
                                    alt={product.name}
                                    src={product.image1 ? product.image1 : require(`../../assets/ilkempicture/woocommerce-placeholder-300x300.png`)}
                                    width="auto" height="auto"
                                    title="Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone"
                                    loading="eager"
                                  ></img>
                                </a>
                              </div>
                              <CardBody>
                                <a
                                  href="#pablo"
                                  onClick={() => toggleModal(product.id)}
                                >
                                  <CardTitle tag="h4">{product.name}</CardTitle>
                                </a>
                              </CardBody>
                            </Card>
                            <Modal
                              isOpen={modalStates[product.id]}
                              toggle={() => toggleModal(product.id)}
                              fullscreen="lg"
                              size="lg"
                              backdrop={false}
                              fade={true}
                              centered={true}
                              scrollable={true}
                            >
                              <ModalHeader
                                toggle={() => toggleModal(product.id)}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {product.name}
                              </ModalHeader>
                              <ModalBody
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Row>
                                  <Col md="12" lg="6">
                                    <div>
                                      {images[product.id].length == 0 ? (
                                        <ThreeDots
                                          visible={true}
                                          height="80"
                                          width="80"
                                          radius="9"
                                          color="#2CA8FF"
                                          ariaLabel='three-dots-loading' 
                                          wrapperStyle={{}}
                                          wrapperClass={styles.wrapperImage}
                                        />
                                      ) : (
                                        <ImageGallery
                                          items={images[product.id]}
                                          showIndex={true}
                                          showPlayButton={false}
                                        />
                                      )}
                                    </div>
                                  </Col>

                                  {/* <div style={{ width: "2em" }}></div> */}

                                  <Col md="12" lg="6">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop:"1em"
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div className={styles.modalDetail}>
                                          {product.id && <h6>Number</h6>}
                                          {product.name && <h6>Name</h6>}
                                          {product.type && <h6>Type</h6>}
                                          {product.brand && <h6>Brand</h6>}
                                          {product.collection && (
                                            <h6>Collection</h6>
                                          )}
                                          {product.country && <h6>Country</h6>}
                                          {product.finish.length!=0 && (
                                            <h6>Finish</h6>
                                          )}
                                          {product.thickness.length !=0 && (
                                            <h6>Thickness</h6>
                                          )}
                                          {product.color.length !=0 && (
                                            <h6>Color</h6>
                                          )}
                                        </div>
                                        <div style={{ width: "2em" }}></div>
                                        <div className={styles.modalDetail}>
                                          {product.id && <h6>{product.id}</h6>}
                                          {product.name && (
                                            <h6>{product.name}</h6>
                                          )}
                                          {product.type && (
                                            <h6>{product.type}</h6>
                                          )}
                                          {product.brand && (
                                            <h6>{product.brand}</h6>
                                          )}
                                          {product.collection && (
                                            <h6>{product.collection}</h6>
                                          )}
                                          {product.country && (
                                            <h6>{product.country}</h6>
                                          )}
                                          
                                            {product.finish.length!=0 && (<h6>{product.finish.join(",")}</h6>)}
                                          
                                          
                                            {product.thickness.length !=0 && (<h6>{product.thickness.join(",")}</h6>)}
                                         
                                          
                                            {product.color.length !=0 && (<h6>{product.color.join(",")}</h6>)}
                                          
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Button
                                          color="info"
                                          style={{ fontSize: "0.7em" }}
                                          onClick={() =>
                                            handleRedirect(product)
                                          }
                                        >
                                          Get a Special Offer
                                        </Button>{" "}
                                        &nbsp;&nbsp;
                                        <Button
                                          color="secondary"
                                          onClick={() => toggleModal(product.id)}
                                        >
                                          Back to Filter
                                        </Button>
                                      </div>
                                      <div
                                        className="info info-horizontal"
                                        style={{ padding: "0" }}
                                      >
                                        <div className="icon icon-info">
                                          <i className="now-ui-icons tech_mobile"></i>
                                        </div>
                                        <div className="description">
                                          <h4 className="info-title">
                                            Give us a ring
                                          </h4>
                                          <p>
                                            (973) 891-5566 <br></br>
                                            Mon - Fri, 8:00-22:00
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </ModalBody>
                              <ModalFooter></ModalFooter>
                            </Modal>
                          </Col>
                        );
                      })}
                    </Row>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      productsPerPage={productsPerPage}
                      totalProducts={
                        typeFilteredProduct.length === 0 &&
                        colorFilteredProduct.length === 0 &&
                        countryFilteredProduct.length === 0 &&
                        finishFilteredProduct.length === 0
                          ? products.length
                          : Array.from(
                              new Set(
                                typeFilteredProduct.concat(
                                  colorFilteredProduct,
                                  countryFilteredProduct,
                                  finishFilteredProduct
                                )
                              )
                            ).length
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      ) : (
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass={styles.wrapper}
        />
      )}
    </>
  );
};

export default Filter;

//json convert için kullanıldı
// function convertJSON (data) {
// const changedJSON = []
//   Object.keys(data).map((data2,index)=>{
//     let obje = {id:index+1,name:data2,...Object.values(data)[index]}
//     // console.log(obje)
//     if(Object.values(data)[index].Brand=="Ilkem Custom Quartz")
//     obje = {...obje,type:"Quartz"}
//     if(Object.values(data)[index].Brand=="Silestone Quartz")
//     obje = {...obje,type:"Quartz"}
//     if(Object.values(data)[index].Brand=="Dekton")
//     obje = {...obje,type:"Dekton"}
//     if(Object.values(data)[index].Brand=="Sensa Granite")
//     obje = {...obje,type:"Granite"}
//     if(Object.values(data)[index].Brand=="Quartzite")
//     obje = {...obje,type:"Quartzite"}
//     if(Object.values(data)[index].Brand=="Marble")
//     obje = {...obje,type:"Marble"}
//     changedJSON.push(obje)
//   })
//   console.log(changedJSON)
// }
// convertJSON(data)
