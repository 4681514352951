import React from "react";
import styles from "./Headers.module.scss"

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Form,
  Input,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
const items = [
  {
    src: "url(" + require("assets/ilkempicture/Design-Home-1.jpg") + ")",
    content: (
      <Row>
        <Col className="ml-auto mr-auto text-left" md="8">
          <h2 className={`title ${styles.shadowCustom1}`} style={{fontSize:"3.5em", lineHeight:"1.15"}}>The Epitome of Luxury and Elegance: <br></br> Marble and Granite</h2>
          <h3 style={{display:"none"}}>Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone.</h3>
          {/* <h4 className="description">
          Add a touch of luxury and elegance to every corner of your home with marble and granite décor. From kitchen countertops to bathroom sinks, staircase steps to coffee tables, these natural stones combine aesthetic appeal and durability to create a mesmerizing ambiance that will leave you spellbound.
          </h4> */}
        </Col>
      </Row>
    ),
    altText: "",
    caption: ""
  },
  {
    src: "url(" + require("assets/ilkempicture/KIT_003.jpg") + ")",
    content: (
      <Row>
        <Col className="ml-auto mr-auto text-left" md="8">
          <h2 className={`title ${styles.shadowCustom2}`} style={{fontSize:"3.5em", lineHeight:"1.15"}}>Eternal Aesthetics: <br></br> Dekton and Quartizite Shine in Home Decoration</h2>
          <h3 style={{display:"none"}}>Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone.</h3>
          {/* <h4 className="description">
          Seeking to make your home décor eternally captivating? Marble and granite are premier materials that will make your living spaces shine brilliantly. From wall claddings to fireplaces, tabletops to decorative accents, these elements offer aesthetics and sophistication that will elevate your everyday living.
          </h4> */}
        </Col>
      </Row>
    ),
    altText: "",
    caption: ""
  },
  {
    src: "url(" + require("assets/ilkempicture/kapak3.jpg") + ")",
    content: (
      <Row>
        <Col className="m-auto text-left" md="8">
          <h2 className={`title ${styles.shadowCustom3}`} style={{fontSize:"3.5em", lineHeight:"1.15"}}>Our New Slab Showroom: <br></br>1 Fairfield Rd, North Caldwell, NJ, 07006</h2>
          <h3 style={{display:"none"}}>Discover quartz, granite, marble, and quartzite surfaces at Bordo Stone.</h3>
          {/* <h4 className="description">
          Desire to add an exquisite touch to your home and revel in a sense of uniqueness? Discover the possibilities of marble and granite. Extend beyond countertops and explore wall panels, floorings, and furniture accents, where these materials' aesthetic and chic nature will transform your living spaces.
          </h4> */}
        </Col>
      </Row>
    ),
    altText: "",
    caption: ""
  }
];

const Headers = () => {
  // navbar collapses states and functions
  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  const [navbarOpen2, setNavbarOpen2] = React.useState(false);
  const [navbarOpen3, setNavbarOpen3] = React.useState(false);
  // header 3 carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
        <div className="cd-section" id="headers">
        <h1 style={{display:"none"}}>Discover quartz, granite, marble, and quartzite surfaces</h1>
        <div className="header-3">
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {items.map((item) => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <div className="page-header header-filter">
                    <div
                      className="page-header-image"
                      style={{
                        backgroundImage: item.src
                      }}
                    ></div>
                    <div className="content">
                      <Container className="text-left">
                        {item.content}
                      </Container>
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div>
      </div>

    </>
  )
}

export default Headers